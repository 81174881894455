<template>
  <div>
    <div class="pb-3">
      <!-- refresh button -->
      <b-button class="ml-1" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') }}</span>
      </b-button>
      <!-- clear button -->
    </div>
    <div>
      <!-- List page filter -->
      <permission-list-filter class="d-inline-block w-75" @search="setFilter" v-model="filter"></permission-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="permissions-table"
        ref="permissionsTable"
        @row-selected="handleSelection"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="getPermissions">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PERMISSIONS')}) }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <template #head(category)="">
        <span>{{ labels.category }}</span>
      </template>

      <template #cell(category)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t(data.item._embedded.category.name.replace('_PERMISSION_CATEGORY', '')) }}</span>
        </div>
      </template>

      <template #head(authority)="">
        <span>{{ labels.authority }}</span>
      </template>

      <template #cell(authority)="data">
        <span>{{ $t(data.item.authority) }}</span>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="profiles-table"
          ></b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL') }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import Permissions from "@/users/permissions";
import eventBus from "@/events";
import Constants from "@/constants";
import PageSize from "@/components/PageSize";
import PermissionListFilter from "./PermissionListFilter";

export default {
  name: "PermissionList",
  props: ['selectMode', 'currentlySelected'],
  components: {PageSize, PermissionListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_PERMISSION_LIST, (totalElements) => {
      this.totalRows = totalElements;
      if (this.currentlySelected) {
        this.originals = this.currentlySelected;
      }
    });
  },
  methods: {
    getPermissions: Permissions.findAll,
  },
  data() {
    return {
      username: '',

      labels: {
        category: this.$t('PERMISSION_CATEGORY_LABEL'),
        authority: this.$t('PERMISSION_AUTHORITIES_LABEL'),
      },

      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'category', sortable: true, tdClass: 'customTd'},
        {key: 'authority', sortable: true, tdClass: 'nameTd'}
      ]
    },
    tableRef() {
      return this.$refs.permissionsTable;
    }
  }
}
</script>

<style>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
