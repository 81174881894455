import http from "@/http";

const PermissionCategories = {
    findAll: async function() {
        const resp = await http.get('/permissionCategories');
        console.log(resp.data);
        return resp.data._embedded.permissionCategories;
    }
};

export default PermissionCategories;
