import http from "@/http";
import eventBus from "@/events";
import Constants from "@/constants";

const Permissions = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'name') + sortDesc;

            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, sort};
            if(size) {
                pagination.size = size;
            }
            const params = {...filter, ...pagination};
            const resp = await http("/permissions/search/all", {params});
            eventBus.$emit(Constants.EVENT_ON_PERMISSION_LIST, resp.data.page.totalElements);
            return resp.data._embedded.permissions;
        } else {
            const resp = await http("/permissions/search/all");
            eventBus.$emit(Constants.EVENT_ON_PERMISSION_LIST, resp.data.page.totalElements);
            return resp.data._embedded.permissions;
        }
    },
    findByUserId: function(id) {
        return http.get('/users/' + id + '/permissions');
    },
    findByProfileId(id) {
        return http.get('/profiles/' + id + '/permissions');
    }
}

export default Permissions;
